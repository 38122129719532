/* eslint-disable no-useless-escape */

import { clsx, type ClassValue } from "clsx";
import { format } from "date-fns";
import dayjs from "dayjs";
import { twMerge } from "tailwind-merge";

import { SETUP_PAYMENT_URL } from "@libs/constants";

export const xAxisTickFormatter = (value: number, hourlyPeriod = true): string => {
  return hourlyPeriod ? dayjs(value).format("h:mm") : dayjs(value).format("MM/DD");
};

export const yAxisTickFormatter = (value: number): string => {
  const absValue = Math.abs(value);
  const units = [
    { threshold: 1_000_000, unit: "M" },
    { threshold: 1_000, unit: "k" },
  ];

  const { unit } = units.find(({ threshold }) => absValue >= threshold) || {
    unit: "",
  };
  const divisor = unit === "M" ? 1_000_000 : unit === "k" ? 1_000 : 1;

  const result = value / divisor;
  const formattedResult = result % 1 === 0 ? String(Math.floor(result)) : result.toFixed(1);

  return `${formattedResult}${unit}`;
};

// export const getUnitRepresentation = (abbreviation: MetricUnit): string => {
//   const units: {
//     [key in MetricUnit]?: string;
//   } = {
//     SEC: "seconds",
//     KB: "kilobytes",
//     KBS: "kilobytes per second",
//     NO_REQUESTS: "number of requests",
//     DOLLARS: "dollars",
//   };

//   return units[abbreviation] || "";
// };

export function isSortedByTime(data: any): boolean {
  if (data?.length <= 1) {
    // an empty array or a single-element array is always considered sorted
    return true;
  }

  for (let i = 1; i < data.length; i++) {
    if (data?.[i]?.time < data?.[i - 1]?.time) {
      // the array is not sorted in ascending order by time
      return false;
    }
  }

  // the array is sorted in ascending order by time
  return true;
}

export function sortByTime<T extends { time: number }>(data: T[]): T[] {
  return data?.sort((a, b) => a?.time! - b?.time!) || [];
}

export const addHttps = (url: string) => {
  if (!/^https?:\/\//i.test(url)) {
    url = "https://" + url;
  }
  return url;
};

export function filterLogsBySummary<
  T extends {
    summary: string;
  },
  >(logsList: T[], query: string): T[] {
  if (!logsList) {
    return [];
  }
  if (!query) {
    return logsList;
  }

  const regex = new RegExp(query, "i");

  return logsList.filter((log) => regex.test(log.summary));
}

export function countLines(inputString: string): number {
  // Split the input string into an array of lines using the newline character ('\n') as the delimiter
  const lines = inputString.split("\n");

  // Return the number of lines in the array
  return lines.length;
}

export const formatDateForBlogPosts = (date: string) => {
  if (!date) return "";
  const d = new Date(date);
  return format(d, "LLLL	d, yyyy");
};

export const getTagLink = (tag?: string) => {
  return !tag ? "/posts" : `/posts?tags=${tag}`;
};

export const getSetupPaymentPath = (redirectUrl?: string) => {
  if (!redirectUrl) return SETUP_PAYMENT_URL;
  return `${SETUP_PAYMENT_URL}?redirect=${encodeURIComponent(redirectUrl)}`;
};

export const scrollToTitleId = (id: string) => {
  const element = document.getElementById(id);

  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  } else {
    console.warn(`Element with id ${id} doesn't exist.`);
  }
};

export const cn = (...args: ClassValue[]) => {
  return twMerge(clsx(args));
};

export const getPackageNameAndVersion = (packageName: string) => {
  let name = decodeURIComponent(packageName).replace(" ", "+");
  if (name.indexOf("@") > -1) {
    let [packageName, version] = name.split("@", 2);
    return {packageName, version, hash: null};
  }
  else if (name.indexOf(":") > -1) {
    let [packageName, hash] = name.split(":", 2);
    
    return {packageName, hash: `sha256:${hash}`, version: null};
  }
  return {packageName: name, hash: null, version: null};
};

export function bytesToSize(bytes?: number): string {
  bytes = bytes || 0;
  const units = ["byte", "kilobyte", "megabyte", "gigabyte", "terabyte"];

  const navigatorLocal = (typeof navigator !== "undefined" && navigator?.languages?.[0]) || "en-US";
  const unitIndex = Math.max(0, Math.min(Math.floor(Math.log(bytes) / Math.log(1024)), units.length - 1));

  return Intl.NumberFormat(navigatorLocal, {
    style: "unit",
    unit: units[unitIndex],
    unitDisplay: "narrow",
    // maximumFractionDigits: 1,
    maximumSignificantDigits: 3,
  }).format(bytes / 1024 ** unitIndex);
}

export function addPatchToVersion(version?: string) {
  if (!version) {
    return null;
  }
  else {
    try {
      const patch = version.split(".")[2];
      const restOfVersion = version.split(".").splice(0, 2);
      const patchBumped = !isNaN(parseInt(patch)) ?
        (parseInt(patch) + 1).toString()
        :
        patch;
      return `${restOfVersion.join(".")}.${patchBumped}`;
    }
    catch (e) {
      console.error("Error parsing version:", version);
    }
  }
}

export function changePackageVersionInURL(url: string, newVersion: string): string {
  // Check if the URL contains a version number in the format "@x.x.x somthing" like @3.12.2+build.4
  const versionMatch = url.match(/@(\d+\.\d+\.\d+[^\/]*)/);

  if (versionMatch) {
    const currentVersion = versionMatch[1];
    const updatedURL = url.replace(currentVersion, newVersion);
    return updatedURL;
  } else {
    const parts = url.split("/");
    const insertIndex = 2; // Assuming the format is "/{owner}/{repository}@{version}/..."
    const newParts = parts.map((part, idx) => {
      if (idx === insertIndex) {
        return `${part}@${newVersion}`;
      }
      return part;
    });
    const updatedURL = newParts.join("/");
    return updatedURL;
  }
}

export function firstLetterUppercase(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
export function removeHtmlTags(htmlString: string): string {
  const regex = /<\/?[^>]+(>|$)/g;
  return htmlString.replace(regex, "");
}

export const extractTitles = (htmlString: string): Array<[string, string]> => {
  const headingTags = ["title", "h1", "h2", "h3", "h4", "h5", "h6"];

  const titles: Array<[string, string]> = [];

  const lines = htmlString.split("\n");
  for (const line of lines) {
    for (const tag of headingTags) {
      const regex = new RegExp(`<${tag}>(.*?)<\/${tag}>`, "i");
      const match = line.match(regex);
      if (match) {
        titles.push([tag, match[1]]);
      }
    }
  }
  return titles;
};

export const extractTitlesFromMarkdown = (markdownText: string) => {
  const titles = [];
  const lines = markdownText.split("\n");

  for (let line of lines) {
    // Matches lines starting with one or more '#' followed by a space
    const match = line.match(/^#+\s+(.*)/);
    if (match) {
      titles.push(match[1]);
    }
  }

  return titles;
};
