import React, { MouseEvent, ReactNode } from "react";

import Link from "next/link";
import clsx from "clsx";
import css from "./IconButton.module.css";

interface IconButtonInterface {
  icon?: ReactNode;
  iconInactive?: ReactNode;
  iconDisabled?: ReactNode;
  link?: string;
  disabled?: boolean;
  inactive?: boolean;
  rounded?: boolean;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement | HTMLAnchorElement>;
}

const IconButton = ({
  icon,
  iconInactive,
  iconDisabled,
  link,
  disabled,
  inactive,
  rounded = false,
  className,
  onClick,
}: IconButtonInterface) => {
  const _onClick = (e: MouseEvent<HTMLDivElement | HTMLAnchorElement>) => {
    if (!inactive && !disabled && onClick) {
      onClick(e);
    }
  };

  return (
    <>
      {!link && (
        <div
          className={clsx(
            !inactive && !disabled && css.default,
            inactive && disabled && css.inactive,
            disabled && css.disabled,
            rounded && css.rounded,
            className
          )}
          onClick={_onClick}
          data-cy="icon-button"
        >
          {!inactive && !disabled && icon}
          {inactive && !disabled && iconInactive}
          {disabled && iconDisabled}
        </div>
      )}
      {link && (
        <Link
          className={clsx(
            !inactive && !disabled && css.default,
            inactive && disabled && css.inactive,
            disabled && css.disabled,
            rounded && css.rounded,
            className
          )}
          onClick={_onClick}
          href={link}
          data-cy="icon-button"
        >
          {!inactive && !disabled && icon}
          {inactive && !disabled && iconInactive}
          {disabled && iconDisabled}
        </Link>
      )}
    </>
  );
};
export default IconButton;
