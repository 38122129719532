"use client";

import clsx from "clsx";
import Link from "next/link";
import { MouseEvent, ReactNode } from "react";

//import Link from "next/link"
import { getNodeText } from "@utils/string";

import css from "./RegularButton.module.css";

export type ButtonStateType = "danger";

export interface RegularButtonInterface {
  text: string | ReactNode;
  variant?: "primary" | "secondary" | "tertiary" | "plain" | "secondary-on-black";
  state?: ButtonStateType;
  iconStart?: ReactNode;
  iconEnd?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement | HTMLAnchorElement>;
  inactive?: boolean;
  disabled?: boolean;
  className?: string | object;
  link?: string;
  theme?: any;
}

const RegularButton = ({
  theme = {},
  text,
  variant = "primary",
  state,
  iconStart,
  iconEnd,
  inactive,
  disabled,
  className,
  link,
  onClick,
  ...otherProps
}: RegularButtonInterface) => {
  const _onClick = (e: MouseEvent<HTMLDivElement | HTMLAnchorElement>) => {
    if (!inactive && !disabled && onClick) {
      onClick(e);
    }
  };

  return (
    <>
      {(!link || disabled || inactive) && (
        <div
          {...otherProps}
          className={clsx(
            [theme.wrapper, css.wrapper],
            [theme[variant], css[variant]],
            state && [theme[state], css[state]],
            inactive && [theme.inactive, css.inactive],
            disabled && [theme.disabled, css.disabled],
            !!iconStart && !!iconEnd && (!text || getNodeText(text) == "") && css.justifyBetween,
            !!iconStart && !iconEnd && (!text || getNodeText(text) == "") && css.justifyLeft,
            !!iconEnd && !iconStart && (!text || getNodeText(text) == "") && css.justifyRight,
            className
          )}
          onClick={_onClick}
          data-cy="regular-button"
        >
          {iconStart && <div className={clsx([theme.iconWrapper, css.iconWrapper])}>{iconStart}</div>}
          <div className={clsx([theme.text, css.text])}>{text}</div>
          {iconEnd && <div className={clsx([theme.iconWrapper, css.iconWrapper])}>{iconEnd}</div>}
        </div>
      )}
      {link && !disabled && !inactive && (
        <Link
          {...otherProps}
          className={clsx(
            [theme.wrapper, css.wrapper],
            [theme[variant || "primary"], css[variant || "primary"]],
            inactive && [theme.inactive, css.inactive],
            disabled && [theme.disabled, css.disabled],
            !!iconStart && !!iconEnd && (!text || getNodeText(text) == "") && css.justifyBetween,
            !!iconStart && !iconEnd && (!text || getNodeText(text) == "") && css.justifyLeft,
            !!iconEnd && !iconStart && (!text || getNodeText(text) == "") && css.justifyRight,
            className
          )}
          onClick={_onClick}
          href={link}
        >
          {iconStart && <div className={clsx([theme.iconWrapper, css.iconWrapper])}>{iconStart}</div>}
          <div data-cy="regular-button" className={clsx([theme.text, css.text])}>
            {text}
          </div>
          {iconEnd && <div className={clsx([theme.iconWrapper, css.iconWrapper])}>{iconEnd}</div>}
        </Link>
      )}
    </>
  );
};
export default RegularButton;
