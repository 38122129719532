import { ReactNode } from "react"

const getNodeText: string | number | any = (node: ReactNode) => {
	if (["string", "number"].includes(typeof node)) return node
	if (node instanceof Array) return node.map(getNodeText).join("")
	if (typeof node === "object" && node) return getNodeText((node as any).props.children)
}


const valueParser = (oString: string | null) => {

	if (!oString) return null


	const pSize: IteratorResult<RegExpMatchArray, any> = oString.matchAll(/^(?<pre>\D*?)(?<num>\d*\.\d+|\d+\.\d*|\d+)(?<sp>\s*?)(?<units>.*?)$/mg).next()


	if (pSize.value) {
		const operator = pSize.value[1]
		const nbr = pSize.value[2]
		const unit = pSize.value[4]
		const value = `${nbr}${unit}`

		return { operator, value }
	} else {
		return null
	}
}

const saveTextAsFile = (text: string, fileName: string): void => {
	const blob = new Blob([text], { type: "text/plain" });
	const downloadLink = document.createElement("a");
	downloadLink.download = fileName;
	downloadLink.innerHTML = "Download File";
	if (window.webkitURL) {
		// No need to add the download element to the DOM in Webkit.
		downloadLink.href = window.webkitURL.createObjectURL(blob);
	} else {
		downloadLink.href = window.URL.createObjectURL(blob);
		downloadLink.onclick = (event: MouseEvent): void => {
			if (event.target) {
				document.body.removeChild(event.target as Node);
			}
		};
		downloadLink.style.display = "none";
		document.body.appendChild(downloadLink);
	}

	downloadLink.click();

	if (window.webkitURL) {
		window.webkitURL.revokeObjectURL(downloadLink.href);
	} else {
		window.URL.revokeObjectURL(downloadLink.href);
	}
};


export { getNodeText, valueParser, saveTextAsFile }

